import {
    createError,
    defineNuxtRouteMiddleware,
    extractQueryParameters,
    guestHost,
    useAsyncData,
    useAuth,
    useError,
    useRuntimeConfig,
    useUniversalFetch,
} from '#imports';
import {navigateTo} from '@typed-router';
import {storeToRefs} from 'pinia';
import type {RouteLocationNormalized} from 'vue-router';
import {getMaybeRedirection} from '~/middleware/redirect/redirection';

type RedirectResource = {
    redirect: boolean;
    url?: string;
    statusCode?: number;
    data?: Record<string, string>;
};

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const host = guestHost();

    // Avoid running the middleware twice when facing an error
    // See: https://github.com/nuxt/nuxt/issues/25084#issuecomment-1939607226
    if (useError().value) {
        return;
    }

    // MALT-3063: if someone inject malicious query params it can lead to a 400
    // This code prevents redirection loop:
    // If the route you are navigating to is equal to the route you have been redirected from, you are in a redirection loop.
    // To avoid this, we redirect you to guestHost()
    if (to.fullPath === from.redirectedFrom?.fullPath) {
        return navigateTo(host, {external: true});
    }

    const config = useRuntimeConfig();
    const queryParams = extractQueryParameters(to) || '';

    const {data, error} = await useAsyncData('profile-alias-and-other-redirect', async () => {
        let redirectResponse;
        const maybeRedirection = getMaybeRedirection(to, host, queryParams);

        // Only fetch this if no redirection is required
        if (!maybeRedirection.redirect || !maybeRedirection.url) {
            redirectResponse = await useUniversalFetch<RedirectResource>(`/profile/public-api/${to.params.profileIdOrAlias}/url?${queryParams}`, {
                retry: false,
            });
        }

        return {
            maybeRedirection,
            redirectResponse,
        };
    });

    if (error.value) {
        // MALT-3063: if someone inject malicious query params it can lead to a 400
        // Redirect to same Route 'to' without any query param (if not empty)
        if (error.value.statusCode === 400 && queryParams.length > 0) {
            return await navigateTo(to.path);
        }

        if (error.value.statusCode === 404 || error.value.statusCode === 410 || error.value.statusCode >= 500) {
            throw createError({
                message: error.value.message,
                statusCode: error.value.statusCode,
                cause: error,
                data: (error.value.data as any)?.data || error.value.data,
            });
        }

        const {loggedIn} = storeToRefs(useAuth());
        if (error.value.statusCode === 403 && !loggedIn.value) {
            const redirectParam = config.app.baseURL + to.fullPath + to.hash;
            return await navigateTo(`${host}/signin?redirect=${encodeURIComponent(redirectParam)}`, {external: true});
        }

        throw error.value;
    }

    if (data.value) {
        if (data.value.maybeRedirection && data.value.maybeRedirection.redirect && data.value.maybeRedirection.url) {
            return await navigateTo(data.value.maybeRedirection.url, {external: true});
        }

        if (data.value.redirectResponse) {
            if (data.value.redirectResponse.statusCode === 410) {
                return await navigateTo(`${host}${data.value.redirectResponse.url}`, {external: true});
            }

            if (data.value.redirectResponse.redirect) {
                return await navigateTo(`${host}${data.value.redirectResponse.url}`, {external: true});
            }
        }
    }
});
